import type {
    Customer,
    NotificationStatus,
    NotificationStatusHistory,
    NotificationTemplateId,
    NotificationType,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Notification,
    notNullUndefinedEmptyOrZero,
    replaceMessageTokensWithModelValues,
    shouldLetThreadIn,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { HtmlDialog } from '../Dialogs/HtmlDialog';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Utilities, animate } from '../Utilities';
import { t } from '../t';
import { NotifyUserMessage } from './NotifyUserMessage';
import { SampleMessageDialog } from './SampleMessageDialog';

export class SendNotificationService {
    public static async previewNotification(
        notificationType: NotificationType,
        message: string,
        historicMessage = false,
        scheduled?: boolean
    ) {
        const confirmPrompt = new SampleMessageDialog(
            message,
            notificationType,
            historicMessage ? 'general.ok' : scheduled ? 'general.schedule' : 'general.send'
        );
        const confirm = await confirmPrompt.show();
        return confirm;
    }
    public static async sendBulkHtmlEmail(emailCustomers: Array<Customer>, subject = '', html = '') {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return false;
        }

        subject = subject || SqueegeeLocalStorage.getItem('bulkEmailSubjectTemplate') || '';
        html = html || SqueegeeLocalStorage.getItem('bulkEmailHtmlTemplate') || '';
        if (!emailCustomers.length) return new NotifyUserMessage('notification.no-customers-with-email-selected');

        const subjectEditor = await new TextDialog(
            'Email Subject' as TranslationKey,
            'Email Subject Including Tokens' as TranslationKey,
            subject,
            ''
        );
        subject = await subjectEditor.show();
        if (subjectEditor.cancelled) return;
        SqueegeeLocalStorage.setItem('bulkEmailSubjectTemplate', subject);

        const editor = new TextDialog(
            'Email Content with Tokens' as TranslationKey,
            '',
            html,
            '',
            undefined,
            false,
            'tinymce',
            'general.save'
        );
        html = await editor.show();
        if (editor.cancelled) return;

        SqueegeeLocalStorage.setItem('bulkEmailHtmlTemplate', html);

        const sampleCustomer = emailCustomers[0];
        const templateText = `${subject} ${html}`;
        const sampleModel = await Utilities.getStandardMessageModel({ customer: sampleCustomer, isHtml: true, templateText });
        const sampleHtml = replaceMessageTokensWithModelValues({
            model: sampleModel,
            message: html,
            options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
        });

        const sampleSubject = replaceMessageTokensWithModelValues({
            model: sampleModel,
            message: subject,
            options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
        });

        await new HtmlDialog(sampleSubject as TranslationKey, sampleHtml, true).show();

        const sendPrompt = new Prompt(
            'Send Email' as TranslationKey,
            `Send the email to ${emailCustomers.length} customers, edit it again or cancel?` as TranslationKey,
            {
                okLabel: 'general.send',
                cancelLabel: 'general.cancel',
                altLabel: 'menubar.edit',
            }
        );

        const send = await sendPrompt.show();

        if (sendPrompt.cancelled) return;

        if (send) {
            new LoaderEvent(true);
            let sent = 0;
            for (const emailCustomer of emailCustomers) {
                try {
                    if (!emailCustomer.email) continue;

                    const emailModel = await Utilities.getStandardMessageModel({ customer: emailCustomer, isHtml: true, templateText });
                    const emailHtml = replaceMessageTokensWithModelValues({
                        model: emailModel,
                        message: html,
                        options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
                    });

                    const emailSubject = replaceMessageTokensWithModelValues({
                        model: emailModel,
                        message: subject,
                        options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
                    });

                    await SendNotificationService.send(
                        emailSubject,
                        emailCustomer.email,
                        emailCustomer._id,
                        'Email',
                        SendNotificationService.notificationFrom,
                        emailHtml
                    );

                    sent++;
                } catch (error) {
                    if (
                        !(await new Prompt(
                            'Error Sending' as TranslationKey,
                            `There was an error sending the email to ${emailCustomer.name} (${emailCustomer.email}), ${sent} of ${emailCustomers.length} do you wish to continue?` as TranslationKey,
                            {
                                okLabel: 'continue' as TranslationKey,
                                cancelLabel: 'general.cancel',
                            }
                        ))
                    ) {
                        new LoaderEvent(false);
                        return new NotifyUserMessage('notification.bulk-message-cancelled', {
                            sent: sent.toString(),
                            emailCustomers: emailCustomers.length.toString(),
                        });
                    }
                }
                new LoaderEvent(true, undefined, 'loader.send-sending', undefined, {
                    sent: sent.toString(),
                    total: emailCustomers.length.toString(),
                });
                if (shouldLetThreadIn(emailCustomer, emailCustomers, 250)) await animate();
            }
            new LoaderEvent(false);
        } else {
            await this.sendBulkHtmlEmail(emailCustomers, subject, html);
        }
    }

    public static get notificationFrom() {
        if (ApplicationState.instance.account.businessName) return ApplicationState.instance.account.businessName;
        if (ApplicationState.instance.account.name) return ApplicationState.instance.account.name;
        return ApplicationState.account.email;
    }

    public static async send(
        description: string,
        to: string,
        customerId: string,
        type: NotificationType,
        sentFromName: string,
        message: string | any,
        bcc?: string,
        sent?: string,
        status?: NotificationStatus,
        statusHistory?: Array<NotificationStatusHistory>,
        templateId?: NotificationTemplateId,
        relatedItemId?: string,
        replaceLineBreaks?: boolean,
        jobOccurrenceReminderId?: string,
        sendAtSecondsTimestamp?: number,
        subject?: string
    ): Promise<Notification | undefined> {
        let content = message;

        if (typeof message === 'string' && replaceLineBreaks) content = message.replace(/(?:\r\n|\r|\n)/g, ' <br>');

        const result = await SendNotificationService.sendWithStatus(
            description,
            to,
            customerId,
            type,
            sentFromName,
            content,
            bcc,
            sent,
            status,
            statusHistory,
            templateId,
            relatedItemId,
            jobOccurrenceReminderId,
            sendAtSecondsTimestamp,
            subject
        );

        return (result && result.ok && result.notification) || undefined;
    }

    public static canSendToNumber(number: string) {
        const prefixes = ApplicationState.account.smsMobilePhonePrefixes
            ?.trim()
            .split(',')
            .map(p => p.trim())
            .filter(notNullUndefinedEmptyOrZero);

        if (prefixes?.length && !prefixes.some(p => number.startsWith(p))) {
            return false;
        }
        return true;
    }

    public static async sendWithStatus(
        description: string,
        to: string,
        customerId: string,
        type: NotificationType,
        sender: string,
        message: string | any,
        bcc?: string,
        sent?: string,
        status?: NotificationStatus,
        statusHistory?: Array<NotificationStatusHistory>,
        templateId?: NotificationTemplateId,
        relatedItemId?: string,
        jobOccurrenceReminderId?: string,
        sendAtSecondsTimestamp?: number,
        subject?: string
    ): Promise<{ ok: boolean; notification?: Notification }> {
        if (!customerId) return { ok: false };

        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return { ok: false };
        }

        if (type === 'SMS') {
            const prefixes = ApplicationState.account.smsMobilePhonePrefixes
                ?.trim()
                .split(',')
                .map(p => p.trim())
                .filter(notNullUndefinedEmptyOrZero);

            if (prefixes?.length && !prefixes.some(p => to.startsWith(p))) {
                new NotifyUserMessage('notification.account-sms-prefix-not-allowed');
                return { ok: false };
            }
        }

        const notification = new Notification({
            description,
            address: to,
            addressee: customerId,
            type,
            sender,
            message,
            bcc,
            sent,
            status,
            statusHistory,
            templateId,
            relatedItemId,
            customerId,
            jobOccurrenceReminderId,
            sendAtSecondsTimestamp,
            subject,
        });

        await Data.put(notification);

        return { ok: true, notification };
    }
}
